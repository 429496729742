import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const state = {
  deviceType: '', // 环境判断参数
  isGetToken: false,
  setting: null,
  aliBaseUrl: 'https://file.zhihuischool.cn', // 阿里云保存静态资源基准路径 zhxt-css
  aliFileUrl: 'https://file.zhihuischool.cn', // 阿里云保存静态资源基准路径 zhxt
  questionIndex: 0,
  sourceType: 2,
  page: 1, // 题目收藏，我的错题数据页码
  pageSize: 30, // 题目收藏，我的错题数据每页条数
}

const mutations = {
  changeDeviceType(state, num) {
    state.deviceType = num;
  },
  changeisGetToken(state, boolean) {
    state.isGetToken = boolean;
  },
  'SET_SETTING': function (state, setting) {
    state.setting = { ...state.setting || JSON.parse(localStorage.getItem('user_setting')) || {}, ...setting }
    localStorage.setItem('user_setting', JSON.stringify(state.setting))
  }
}

const actions = {
  changeUserSetting ({ commit }, setting) {
    commit('SET_SETTING', setting)
  }
}

const getters = {
  userSetting: state => state.setting || JSON.parse(localStorage.getItem('user_setting')) || {}
}

export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters,
  modules: {
  }
})
