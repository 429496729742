import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import request from './api/request'
// import appMethod from './components/utils/jssdk'
// import Bridge from './api/JSBridge'
import Mixin from './mixins'
import vuescroll from 'vuescroll'
import loadingmodle from '@/components/loading/Loading'
import 'lib-flexible'
import IconSvg from '@/components/iconSvg/index.vue'
import { Checkbox, CheckboxGroup, Grid, Search, RadioGroup, Radio, Dialog, Uploader, Toast, Cascader, Area, ShareSheet, Popover, Sticky, Button, NavBar, DropdownMenu, DropdownItem, Form, Field, DatetimePicker, Picker, Tab, Tabs, ActionSheet, Image as VanImage, NoticeBar } from 'vant'
import './plugins/vant' // vant组件
import _ from 'lodash'
import uploadAli from '@/api/uploadAli'
// import Vconsole from 'vconsole'
import common from '@/utils/common'
import zhxtCore from 'components/utils/zhxtCore'
import way from '@/api/encapsulation'
import 'swiper/css/swiper.css'
import VueAwesomeSwiper from 'vue-awesome-swiper'

import { Pagination, Tag, Tooltip, Table, TableColumn, Loading } from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import 'element-ui/lib/theme-chalk/icon.css'
import VueClipBoard from 'vue-clipboard2'
import './styles/index.scss'

Vue.use(VueClipBoard)

if (process.env.VUE_APP_CURRENTMODE != 'production') {
  // let vConsole = new Vconsole()
  // Vue.use(vConsole)
}

Vue.use(VueAwesomeSwiper)
// 公共封装
Vue.prototype.$common = common
Vue.prototype.$way = way
Vue.prototype.$zhxtCore = zhxtCore
Vue.prototype._ = _
Vue.prototype.$uploadAli = uploadAli

Vue.use(CheckboxGroup).use(Checkbox).use(Grid).use(Search).use(Radio).use(RadioGroup).use(Dialog).use(Uploader).use(Toast).use(Cascader).use(Area).use(ShareSheet).use(Popover).use(Sticky).use(Button).use(NavBar).use(DropdownMenu).use(DropdownItem).use(Form).use(Field).use(DatetimePicker).use(Picker).use(Tab).use(Tabs).use(ActionSheet).use(VanImage)
Vue.use(Table).use(TableColumn).use(Tooltip).use(Tag).use(Pagination).use(Loading).use(NoticeBar)

Vue.use(loadingmodle)
Vue.use(vuescroll)

Vue.component('icon-svg', IconSvg)
Vue.prototype.$vuescrollConfig = {
  bar: {
    showDelay: 500, // 在鼠标离开容器后多长时间隐藏滚动条
    onlyShowBarOnScroll: false, // 当页面滚动时显示
    keepShow: false, // 是否一直显示
    background: '#c3c3c3',
    opacity: 1,
    hoverStyle: false,
    specifyBorderRadius: false,
    minSize: false,
    size: '6px',
    disable: false// 是否禁用滚动条
  },
  name: 'vueScroll' // 在这里自定义组件名字，默认是vueScroll
}

Vue.mixin(Mixin)

Vue.config.productionTip = false

// 请求封装
Vue.prototype.$request = request
// Vue.prototype.$appMethod = appMethod
// Vue.prototype.$bridge = Bridge
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
