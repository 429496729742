<template>
  <div class="nvaBar">
    <img class="back" @click="goBack" src="../assets/img/goBack.png" alt="">
    <span>{{navData.title}}</span>
    <span></span>
  </div>
</template>

<script>
export default {
  name: 'nvaBar',
  props: ['navData'],
  data() {
    return {
      
    }
  },
  methods: {
    goBack() {
      if(window.webkit != undefined){
        window.webkit.messageHandlers.Close.postMessage(null);
      }else if(window.Android){
        window.Android.Close();
      }
    },
  },
}
</script>

<style lang='scss'>
  .nvaBar {
    // position: fixed;
    // top: 0;
    // left: 0;
    width: 100%;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    box-shadow: 0 2px 5px #F9F9F9;
    box-sizing: border-box;
    padding: 0 23px;
    z-index: 900;
    .back {
      width: 11px;
      height: 19px;
    }
    & > span {
      font-size: 18px;
      font-weight: 400;
      color: #333333;
    }
  }
</style>