<template>
  <div id="app" @click="handleClick">
    <router-view/>
  </div>
</template>

<script>
export default {
  data () {
    return {
      clickCount: 0, // 记录点击次数
      clickTimeout: null // 点击超时重置
    }
  },
  methods: {
    handleClick () {
      this.clickCount++

      // 如果超过 500ms 没有点击，重置点击计数
      clearTimeout(this.clickTimeout)
      this.clickTimeout = setTimeout(() => {
        this.clickCount = 0
      }, 500)

      // 当点击次数达到 10 次时加载 vConsole
      if (this.clickCount === 10) {
        this.loadVConsole()
        this.clickCount = 0 // 重置点击计数
      }
    },
    loadVConsole () {
      if (document.getElementById('__vconsole').style.visibility !== 'hidden') {
        document.getElementById('__vconsole').style.visibility = 'hidden'
      } else {
        document.getElementById('__vconsole').style.visibility = 'visible'
      }
    }
  },
  mounted () {
    const loadingElement = document.getElementById('appInitLoading')
    if (loadingElement) {
      console.log('隐藏 loading')

      loadingElement.style.display = 'none'
    }
  }
}
</script>
<style lang="scss">
  * {
    padding: 0;
    margin: 0;
  }
  html {
    height: 100%;
  }
  #app {
    width: 100%;
    height: 100vh;
    -webkit-overflow-scrolling: touch;
    overflow: auto!important;
  }
</style>
