import axios from 'axios';
import QS from 'qs';
import router from '../router'
import request from "./request";
import store from '../store'
import updateSignObj from './signatureVerification'

let requestNum = 0; // 当前模块接口请求次数

let hideLodingArr = [
  '/manage/student/courseStudy/workAnswer',
  '/manage/student/exam/begin',
  '/manage/student/exam/question/list',
  '/manage/student/courseStudy/questionList',
  '/manage/question/record/answer',
  '/manage/student/courseStudy/getEbookRecordId',
  '/manage/student/courseStudy/ebookStudy',
  '/manage/student/aid/question/review',
  '/manage/student/aid/question/judge',
  '/manage/student/aid/paper/answer',
  '/manage/student/aid/exam/begin',
  '/manage/student/aid/exam/continue',
  '/manage/student/aid/exam/get',
  '/code',
  '/code/check',
  '/manage/student/aid/special/begin',
  '/manage/student/aid/collect/question',
  '/manage/student/aid/exam/again',
  '/manage/temp/tempVo',
  '/manage/student/patrol/pull/msg',
  '/manage/student/commonLive/getStatus',
  '/manage/student/courseStudy/study',
  '/manage/student/courseStudy/getRecordId'
]
let needGobackLoginList = [
  '/manage/sysstudent/page',
]

// 请求超时时间
// axios.defaults.timeout = 10000;

// post请求头
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8';
axios.defaults.withCredentials = true;

// 请求头新增验签字段
function reqHeaderAddWord(config) {
  let signObj = updateSignObj()
  for (let key in signObj) {
    config.headers[key] = signObj[key];
  }
}

let url_origin = location.origin

// 接口请求基准路径配置
axios.defaults.baseURL = process.env.NODE_ENV == "development" ? '/' : url_origin

// 请求拦截器
axios.interceptors.request.use(config => {
  // console.log('config',config);
  requestNum++;
  var token = sessionStorage.token;
  let tenantId = sessionStorage.getItem("tenant_id");
  if (token && config.url!=='/auth/oauth/token') {
    config.headers['Authorization'] = 'Bearer ' + token// token
  }
  if (tenantId) {
    config.headers['TENANT-ID'] = tenantId;
  }
  // 每次请求更新验签字段
  reqHeaderAddWord(config)
  // config.headers['Authorization'] = 'Bearer 9ac9ded0-b16c-4eed-910c-7a6b5a87d9a9'// token
  // config.headers['TENANT-ID'] = 28

  // window.$loading.show();
  let questUrl = config.url.split('?')[0];
  if (!hideLodingArr.includes(questUrl)) {
    window.$loading.show();
  }
  return config
}, error => {
  //  1.判断请求超时  再重复请求一次
  // if (error.code === 'ECONNABORTED' && error.message.indexOf('timeout') !== -1) {
  //     return service.request(originalRequest);
  // }
  
  if(requestNum == 0) requestNum--;
  return Promise.error(error);
})

//axios响应拦截
// 是否正在刷新token
let isRefreshing = false;
// 重试队列 每一项都是一个待执行待函数
let requests = [];
axios.interceptors.response.use((response) => {
  if (!hideLodingArr.includes(response.config.url) || hideLodingArr.indexOf(response.config.url)>-1) requestNum--;
  if(requestNum == 0) window.$loading.hide();
  return response;
}, error => {
  if (!hideLodingArr.includes(error.response.config.url) || hideLodingArr.indexOf(error.response.config.url)>-1) requestNum--;
  if(requestNum == 0) window.$loading.hide();

  console.log(error,'error');
  let questUrl = error.response.config.url.split('?')[0];
  console.log(questUrl,'questUrl');
  if (needGobackLoginList.includes(questUrl)) {
    if (error.response.status == 401) {
      console.log(401);
      if (!isRefreshing) {
        isRefreshing = true;
        request.refreshToken(sessionStorage.getItem("refresh_token")).then(res => {
          if (res.status == 200) {
            let loginData = res.data
            sessionStorage.setItem("token", loginData.access_token);
            sessionStorage.setItem("refresh_token", loginData.refresh_token)
            sessionStorage.setItem("loginData", JSON.stringify(loginData));
            requests.length > 0 && requests.map((cb) => {
              cb();
            });
            requests = [];  //注意要清空
            error.config.headers.Authorization = sessionStorage.getItem('token');
            // location.reload();
            return axios.request(error.config);
          }
        }).catch(err => {
          router.push({ path: '/login' })
          sessionStorage.clear();
        }).finally(() => {
          isRefreshing = false;
        });
      } else {
        // 正在刷新token ,把后来的接口缓冲起来
        return new Promise((resolve) => {
          requests.push(() => {
            error.config.headers.Authorization = sessionStorage.getItem('token');
            error.config.headers.withCredentials = true;
            resolve(axios.request(error.config));
          });
        })
      }
    }
  }
  return Promise.reject(error)
});

/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Boolean} sdt [是否加密,加密传true，否者传false]
 * @param {Object} params [请求时携带的参数]
 */
export function post(url, params, dataType = 'json', responseType = 'json') {
  return new Promise((resolve, reject) => {
    params = dataType == 'json' ? params : QS.stringify(params);
    responseType = responseType == 'blob' ? 'blob' : responseType;
    axios.post(url, params, { responseType })
      .then(res => { resolve(res.data); })
      .catch(err => { reject(err.data); console.log(err) })
  });
}
/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Boolean} sdt [是否加密,加密传true，否者传false]
 * @param {Object} params [请求时携带的参数]
 */
export function get(url, params = {}, dataType = 'json', responseType = 'json') {
  return new Promise((resolve, reject) => {
    params = dataType == 'json' ? params : QS.stringify(params);
    responseType = responseType == 'blob' ? 'blob' : responseType;
    axios.get(url, {params}, { responseType })
      .then(res => { resolve(res.data); })
      .catch(err => { reject(err.data); console.log(err) })
  });
}
/**
 * from表单提交
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function formPost(url, params = {}) {
  return new Promise((resolve, reject) => {
    let form = new FormData();
    form.append('file', params);
    axios({
      url,
      method: "POST",
      headers: { "content-type": "multipart/form-data" },
      data: form
    })
      .then(res => { resolve(res.data); })
      .catch(err => { reject(err.data); console.log(err) })
  });
}

export default axios;
