import { Toast } from 'vant';

let mixin = {
  data(){
    return{};
  },
  methods: {
    // 成功
    Success(msg) {
      Toast.success(msg);
    },
    // // 警告
    // Warn(msg) {
    //   Notify({ type: 'warning', message: msg });
    // },
    // 消息
    Info(msg) {
      Toast(msg);
    },
    // 错误
    Error(msg) {
      Toast.fail(msg);
    },
  }
};
export default mixin;