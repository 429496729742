import request from '../axios'
import qs from 'qs'

const infoRequest = {
  // 个人信息
  getPersonInfo(params) {
    return request({
      url: "/manage/student/person/info",
      method: "get",
      params
    });
  },
  // 验证码
  sendMsg(params) {
    return request({
      url: "/manage/student/person/send/" + params,
      method: "get",
    });
  },
  // 学籍信息确认
  stuStatusComfirm(data) {
    return request({
      url: "/manage/student/person/confirm",
      method: "put",
      data
    });
  },
  // 获取省份
  getProviceNew(params) {
    return request({
      url: "/manage/region/getProvinces",
      method: "get",
      params,
    });
  },
  
  // 获取市
  getCitiesStu(params) {
    return request({
      url: "/manage/region/getCities",
      method: "get",
      params,
    });
  },
  // 多照片实名认证（上传身份证+采集照片）
  onMultiPhotoAuth(data) {
    return request({
      url: "/manage/student/person/real/multiPhotoAuth/stepOne",
      method: "post",
      data
    });
  },
  // 申请人工审核
  auditReview(data) {
    return request({
      url: "/manage/student/person/apply/audit",
      method: "post",
      data
    });
  },
  // 实名认证人工审核单详情
  auditDetail() {
    return request({
      url: "/manage/student/person/auth/audit/details",
      method: "get"
    });
  },
  // 学籍确认信息参数设置
  getConfirmItemList(data) {
    return request({
      url: "/manage/student/confirmItem/list",
      method: "get",
      data,
    });
  },
  // 学籍确认-信息收集项
  stuInfoCollect() {
    return request({
      url: "/manage/student/person/confirm/info/template",
      method: "put"
    });
  },

  // 录取模块
  // 学籍确认
  admitStuInfoConfirm(data) {
    return request({
      url: "/manage/student/admit/confirm",
      method: "put",
      data
    });
  },
  // 录取学生信息
  admitStuInfoDetail() {
    return request({
      url: "/manage/student/admit/info",
      method: "get"
    });
  },
}

export default infoRequest