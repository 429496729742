import request from '../axios'
import { formPost } from '../axios'
import qs from 'qs'

const qrRequest = {
  /**
   * 
   * @param  慕课直播-start
   * @returns 
   */
  // 轮询课件播放视频进度
  postVideo(data) {
    return request({
      url: "/manage/student/courseStudy/study",
      method: "post",
      data,
    });
  },
  // 获取课件播放视频id
  getRecordId(data) {
    return request({
      url: "/manage/student/courseStudy/getRecordId",
      method: "post",
      data,
    });
  },
  // 获取学习轮询时间
  getFrontPollTime() {
    return request({
      url: "/admin/dict/item/poll",
      method: "get",
    });
  },
  //  学生端-获取课件播放地址
  getPlayAuth(params) {
    return request({
      url: "/manage/student/courseStudy/vod",
      method: "get",
      params,
    });
  },
  /**
   * 
   * @param  慕课直播-end
   * @returns 
   */
  /**
   * 
   * @param {} 线下授课课程作业-start
   * @returns 
   */
  // 课程作业-题目列表
  getCourseWorkQList (params) {
    return request({
      url: '/manage/student/offlineteaching/offline/teaching/work/question/list',
      method: 'get',
      params
    })
  },
  // 课程作业-列表
  // getCourseWorkList (params) {
  //   return request({
  //     url: '/manage/student/live/work/question/list',
  //     method: 'get',
  //     params
  //   })
  // },
  // 课程作业-答题
  putCourseWorkAnswer (data) {
    return request({
      url: '/manage/student/offlineteaching/offline/teaching/question/answer',
      method: 'put',
      data: data
    })
  },
  // 课程作业-提交
  putCourseWorkSubmit (params) {
    return request({
      url: '/manage/student/offlineteaching/offline/teaching/work/commit',
      method: 'put',
      params
    })
  },
  /**
   * 
   * @param {} 课程作业-end 
   * @returns 
   */
  /**
   * 
   * @param {} 直播作业-start 
   * @returns 
   */
  // 直播作业-题目列表
  getLiveWorkQList (params) {
    return request({
      url: '/manage/student/live/work/question/list',
      method: 'get',
      params
    })
  },
  // 直播作业-列表
  getLiveWorkList (params) {
    return request({
      url: '/manage/student/live/work/question/list',
      method: 'get',
      params
    })
  },
  // 直播作业-答题
  putLiveWorkAnswer (data) {
    return request({
      url: '/manage/student/live/work/question/answer',
      method: 'put',
      data: data
    })
  },
  // 直播作业-提交
  putLiveWorkSubmit (params) {
    return request({
      url: '/manage/student/live/work/commit',
      method: 'put',
      params
    })
  },
  /**
   * 
   * @param {} 直播作业-end 
   * @returns 
   */
  // 巡考轮询接口
  getExamPullMsg (params) {
    return request({
      url: '/manage/student/patrol/pull/msg',
      method: 'get',
      params
    })
  },
  // // 巡考人脸识别
  // getExamCheckFace (data) {
  //   return request({
  //     url: '/manage/student/patrol/check/face',
  //     method: 'post',
  //     data
  //   })
  // },
  // 获取作业题目列表
  getWorkList (params) {
    return request({
      url: '/manage/student/courseStudy/questionList',
      method: 'get',
      params
    })
  },
  // 作业题目答题
  putWorkAnswer (data) {
    return request({
      url: '/manage/student/courseStudy/workAnswer',
      method: 'put',
      data: data
    })
  },
  // 提交作业
  putWorkSubmit (data) {
    return request({
      url: '/manage/student/courseStudy/workSubmit',
      method: 'post',
      data: data
    })
  },
  // 开始考试
  putExamBegin (params) {
    return request({
      url: '/manage/student/exam/begin',
      method: 'put',
      params
    })
  },
  // 考试交卷
  putExamSubmit (params) {
    return request({
      url: '/manage/student/exam/end',
      method: 'put',
      params
    })
  },
  // 考试题目列表
  getExamPaper (params) {
    return request({
      url: '/manage/student/exam/question/list',
      method: 'get',
      params
    })
  },
  // 考试题目答题
  putExamAnswer (data) {
    return request({
      url: '/manage/question/record/answer',
      method: 'put',
      data
    })
  },
  // 消息中心-消息列表
  getMessageList () {
    return request({
      url: '/manage/student/bscMessage/getMessageList',
      method: 'get'
    })
  },
  // 消息中心-消息详情
  getMessageDetail (params) {
    return request({
      url: `/manage/student/bscMessage/${params.id}`,
      method: 'get'
    })
  },
  // 消息中心-消息确认
  getMessageCheck (data) {
    return request({
      url: `/manage/student/bscMessage/mobileRead/${data.id}`,
      method: 'put'
    })
  },

  // 成考助学接口-start
  // 成考助学开始考试
  putHelpStudyExamBegin (data) {
    return request({
      url: `/manage/student/aid/exam/begin?paperId=${data.paperId}`,
      method: 'post'
    })
  },
  // 成考助学继续考试
  putHelpStudyExamContinue (data) {
    return request({
      url: `/manage/student/aid/exam/continue?paperId=${data.paperId}`,
      method: 'post'
    })
  },
  // 助学(外语学士学位)-重考
  putHelpStudyExamAgain (data) {
    return request({
      url: `/manage/student/aid/exam/again?paperId=${data.paperId}`,
      method: 'post'
    })
  },
  // 成考助学试卷批阅
  putHelpStudyExamReadRemark (params) {
    return request({
      url: `/manage/student/aid/exam/get?paperId=${params.paperId}`,
      method: 'get'
    })
  },
  // 成考助学考试答题
  postHelpStudyExamAnswer (data) {
    return request({
      url: '/manage/student/aid/paper/answer',
      method: 'post',
      data: data
    })
  },
  // 成考助学考试交卷
  putHelpStudyExamEnd (data) {
    return request({
      url: `/manage/student/aid/exam/end?paperId=${data.paperId}`,
      method: 'put'
    })
  },
  // 成考助学批阅提交
  putHelpStudyReviewEnd (data) {
    return request({
      url: `/manage/student/aid/question/endReview?paperId=${data.paperId}`,
      method: 'put'
    })
  },
  // 成考助学批阅改分
  putHelpStudyReview (data) {
    return request({
      url: '/manage/student/aid/question/review',
      method: 'put',
      data: data
    })
  },
  // 成考助学批阅对错判断
  putHelpStudyReviewJudge (data) {
    return request({
      url: '/manage/student/aid/question/judge',
      method: 'put',
      data: data
    })
  },
  // 成考助学题目收藏
  postHelpStudyQuestionCollect (data) {
    return request({
      url: '/manage/student/aid/question/collect',
      method: 'post',
      data: data
    })
  },
  // 助学-专项练习-做题
  postHelpStudyQuestionsPractice (data) {
    return request({
      url: '/manage/student/aid/special/begin',
      method: 'post',
      data: data
    })
  },
  // 助学-专项训练答题 (客观题调用 @return true:正确 false:错误)
  postHelpStudyAnswer (data) {
    return request({
      url: '/manage/student/aid/special/answer',
      method: 'post',
      data: data
    })
  },
  // 助学-专项练习-退出做题
  postHelpStudyEnd () {
    return request({
      url: '/manage/student/aid/special/end',
      method: 'post'
    })
  },
  // 助学-收藏或错题列表-获取题目
  postHelpCollectOrFail (params) {
    return request({
      url: '/manage/student/aid/collect/question',
      method: 'get',
      params
    })
  },
  // 成考助学接口-end

  /** 官网-start */
  // 获取小官网验证码-1.7.1
  getEduCode (params) {
    return request({
      url: `/manage/home/get/graduate/code`,
      method: 'post',
      params
    })
  },
  // 获取小官网毕业信息-1.7.1
  getEduQuery (data) {
    return request({
      url: `/manage/home/get/graduate/detail`,
      method: 'post',
      data
    })
  },
  // 获取小官网录取验证码-1.7.5
  getAdmCode(params) {
    return request({
      url: `/manage/home/get/admit/code`,
      method: 'post',
      params
    })
  },
  // 获取小官网录取信息-1.7.5
  getAdmQuery (data) {
    return request({
      url: `/manage/home/get/admit/detail`,
      method: 'post',
      data
    })
  },
  // 获取首页公告
  getSchoolNotices (params) {
    return request({
      url: '/manage/home/notices',
      method: 'get',
      params
    })
  },
  // 获取学校信息
  getSchoolInfo (params) {
    return request({
      url: '/manage/home/school/info',
      method: 'get',
      params
    })
  },
  // 获取学校图片设置列表
  getSchoolImgInfo (params) {
    return request({
      url: '/manage/school/set/img/get',
      method: 'get',
      params
    })
  },
  // 获取学校新闻
  getSchoolNews (params) {
    return request({
      url: '/manage/home/news',
      method: 'get',
      params
    })
  },
  // 获取学校轮播
  getSchoolCarousel (params) {
    return request({
      url: '/manage/home/h5/carousels',
      method: 'get',
      params
    })
  },
  // 获取首页轮播-new
  getNewSchoolCarousel (params) {
    return request({
      url: '/manage/home/carousels/v2',
      method: 'get',
      params
    })
  },
  // 获取官网新闻、公告列表
  getSchoolPage (params) {
    return request({
      url: '/manage/home/news/page',
      method: 'get',
      params
    })
  },
  // 获取学校热门专业
  getSchoolHotMajors (params) {
    return request({
      url: '/manage/home/majors',
      method: 'get',
      params
    })
  },
  // 获取学校招生简章
  getSchoolBrochure (params) {
    return request({
      url: '/manage/home/brochure',
      method: 'get',
      params
    })
  },
  // 获取学校合作站点
  getSchoolStations (params) {
    return request({
      url: '/manage/home/stations',
      method: 'get',
      params
    })
  },
  /** 官网-end */
  /** 预报名-start */
  // 预报名补充材料提交
  postPreAddMaterialUpload (data) {
    return request({
      url: '/manage/school/preRegistration/auditManage/addMaterialUpload',
      method: 'post',
      data
    })
  },
  // 预报名查询生成授权码-第一步
  postPreRegistrateCode (data) {
    return request({
      url: '/manage/school/preRegistration/auditManage/createCode',
      method: 'post',
      data
    })
  },
  // 预报名查询结果-第二部
  postPreRegistrationQuery (params) {
    return request({
      url: '/manage/school/preRegistration/auditManage/queryVerify',
      method: 'post',
      params
    })
  },
  // 预报名提交
  postPreRegistrationApply (data) {
    return request({
      url: '/manage/school/preRegistration/apply',
      method: 'post',
      data
    })
  },
  // 预报名-获取教学点列表-免登
  getPreRegistrationStations (params) {
    return request({
      url: '/manage/school/preRegistration/getStationsPage',
      method: 'get',
      params
    })
  },
  // 预报名-获取上传附件列表-免登
  getEnrollmentPlanSet(data) {
    return request({
      url: "/manage/school/set/enrollmentPlan/get",
      headers: {
        isToken: false,
        'Authorization': 'Basic cGlnOnBpZw==',
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      method: "get",
      data
    });
  },
  // 预报名-获取省份及直辖市下拉-免登
  getPreRegistrationProvinces (params) {
    return request({
      url: '/manage/school/preRegistration/getProvinces',
      method: 'get',
      params
    })
  },
  // 预报名-获取市级城市和直辖市的区县-免登
  getPreRegistrationCities (params) {
    return request({
      url: '/manage/school/preRegistration/getCities',
      method: 'get',
      params
    })
  },
  // 预报名-发送验证码-免登-mobile
  getSendCode (params) {
    return request({
      url: '/manage/school/preRegistration/forget/sendCode',
      method: 'get',
      params
    })
  },
  // 预报名-招生计划列表-免登
  getPreEnrollmentPlanList (params) {
    return request({
      url: '/manage/school/preRegistration/enrollmentPlan/list',
      method: 'get',
      params
    })
  },
  // 预报名-招生设置信息-免登
  getPreEnrollmentSetInfo (params) {
    return request({
      url: '/manage/school/enrollment/set/forecast/info',
      method: 'get',
      params
    })
  },
  // 预报名-招生计划-专业安排表-免登
  getPreMajorList (params) {
    return request({
      url: '/manage/school/preRegistration/enrollmentPlan/major/list',
      method: 'get',
      params
    })
  },
  /** 预报名-end */
  /** 东华理工临时直播-start */
  // 获取直播状态和推流地址
  getDHLiveTempVo (params) {
    return request({
      url: '/manage/temp/tempVo',
      headers: {
        isToken: false,
        'Authorization': '',
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      method: 'get',
      params
    })
  },
  /** 东华理工临时直播-end */
  /** 公共直播-start */
  // 获取直播详情
  getCommonLiveDetail (params) {
    return request({
      url: '/manage/student/commonLive/details',
      headers: {
        isToken: false,
        'Authorization': '',
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      method: 'get',
      params
    })
  },
  // 获取直播详情状态
  getCommonLiveStatus (params) {
    return request({
      url: '/manage/student/commonLive/getStatus',
      headers: {
        isToken: false,
        'Authorization': '',
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      method: 'get',
      params
    })
  },
  /** 公共直播-end */
  /** 教师端接口-start */
  // 学生基础管理-获取当前学期
  getCurrentSemester (params) {
    return request({
      url: '/manage/Semester/getCurrentSemester',
      method: 'get',
      params
    })
  },
  // 学生基础管理-列表
  getSysstudenPage (params) {
    return request({
      url: '/manage/sysstudent/page',
      method: 'get',
      params
    })
  },
  // 入学批次
  getGoSchool(params) {
    return request({
      url: `/admin/dict/item/down/admission_batch_type`,
      method: "get",
    });
  },
  // 重置密码
  resetScpassword (params) {
    return request({
      url: `/admin/user/st/resetPassword/${params.loginNum}`,
      method: "put",
    });
  },
  // 第一次登录修改密码接口
  firstLogin (data, token, id) {
    return request({
      url: "/admin/center/pt/first/updatePassword",
      headers: {
        Authorization: "Bearer " + token,
        "TENANT-ID": id,
      },
      method: "put",
      data,
    });
  },
  /** 教师端接口-end */

  /** 毕业生登记表-start */
  // 获取学生信息
  getPersonInfo (params) {
    return request({
      url: `/manage/student/person/info`,
      method: "get",
    });
  },
  // 获取毕业生登记
  getGraduateRegister (params) {
    return request({
      url: `/manage/student/person/graduate/register`,
      method: "get",
    });
  },
  // 提交毕业生登记信息
  postGraduateRegisterSubmit (data) {
    return request({
      url: `/manage/student/person/graduate/register/submit`,
      method: "post",
      data
    });
  },
  /** 毕业生登记表-end */
}

export default qrRequest;
