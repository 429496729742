<template>
  <div class="home">
    <!-- <nvaBar :navData='navData' v-if="isShowNavBar"></nvaBar> -->
    <div class="container">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import homeFooter from '@/components/home/homeFooter'
import nvaBar from '../components/nvaBar'
import appMethod from '../components/utils/jssdk'
export default {
  name: 'Home',
  components: {
    nvaBar
    // homeFooter
  },
  watch: {
    $route: {
      immediate: true,
      handler (newVal, oldVal) {
        // console.log(newVal)
        this.deviceType = appMethod.isZHXTapp()
        this.$store.commit('changeDeviceType', this.deviceType)
        this.changeNav(newVal)
        // if(newVal.meta.isShowNav) {
        //   this.isShowNavBar = true;
        //   document.getElementsByTagName('body')[0].style.setProperty('--cont-height','44px')
        // } else {
        //   this.isShowNavBar = false;
        //   document.getElementsByTagName('body')[0].style.setProperty('--cont-height','0')
        // }
      }
    }
  },
  data () {
    return {
      navData: {
        title: ''
      },
      deviceType: '', // 1.ios 2.Android 3.PC 4.微信-ios 5.微信-安卓
      netStatus: '',
      isShowNavBar: true
    }
  },
  mounted () {
    this.getUserInfo()
    this.isNetWork()
    window.addEventListener('online', this.updateOnlineStatus)
    window.addEventListener('offline', this.updateOnlineStatus)
  },
  beforeDestroy () {
    window.removeEventListener('online', this.updateOnlineStatus)
    window.removeEventListener('offline', this.updateOnlineStatus)
  },
  methods: {
    // 初始化进行网络判断
    isNetWork () {
      this.netStatus = navigator.onLine ? 'online' : 'offline'
    },
    // 监听网络状态
    updateOnlineStatus (e) {
      // console.log(e);
      const { type } = e
      this.netStatus = type
      if (type === 'online') { // 网络由异常到正常时触发
        this.Success('网络正常')
      } else if (type === 'offline') { // 网络由正常到异常时触发
        this.Error('网络异常')
      }
    },
    changeNav (data) {
      if (!data.meta.hasOwnProperty('title')) return
      const val = data.meta.title
      if (this.deviceType == 1) {
        window.webkit.messageHandlers.postTitle.postMessage(val)
      } else if (this.deviceType == 2) {
        window.Android.setNewTitle(val)
      }
      this.navData.title = val
    },
    getUserInfo () {
      if (this.deviceType == 1) {
        window.webkit.messageHandlers.getData.postMessage('tokenid')
        window.getUserInfo = appMethod.getUserInfo
      } else if (this.deviceType == 2) {
        try {
          window.Flutter.postMessage('tokenid')
          window.getUserInfo = appMethod.getUserInfo
        } catch (error) {
          console.log(error, 'flutter')
        }
        try {
          const userData = JSON.parse(window.Android.getUserInfo())
          sessionStorage.setItem('token', userData.token || '')
          sessionStorage.setItem('tenant_id', userData.tenantid || '')
          this.$store.commit('changeisGetToken', true)
        } catch (error) {
          console.log(error, '原生')
        }
      } else if (this.deviceType == 4 || this.deviceType == 5) {
        sessionStorage.setItem('token', '')
        sessionStorage.setItem('tenant_id', '')
      } else {
        // 小官网本地需要注释掉
        sessionStorage.setItem('token', '92028cb6-ceff-47c4-90c4-8c0c3c15e304')
        sessionStorage.setItem('tenant_id', 228) // zl1   zl12
        this.$store.commit('changeisGetToken', true)
        // sessionStorage.setItem('token', '8c5c390e-6920-4fd0-a02b-81fc46467256')
        // sessionStorage.setItem('tenant_id', 129) // 黄彩霞 广东大学
      }
    }
  }
}
</script>

<style lang="scss">
  .home {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    position: relative;
    .nvaBar {
      position: absolute;
      top: 0;
      left: 0;
    }
    .container {
      width: 100%;
      height: 100%;
    }
  }
</style>
