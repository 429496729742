<template>
  <!-- 登录首页入口 -->
  <div class="loginHome" id="loginHome">
    <!-- logo、菜单 -->
    <div class="header">
      <div class="headerSticky">
        <img class="headerL" src="@/assets/img/home/menuIcon.png" alt="" @click="showNav = !showNav" srcset="">
        <!-- <div class="headerL">

        </div> -->
        <img class="headerR" :src="schoolInfo.logoUrl || ''" alt="">
        <!-- <div class="headerR">
        </div> -->
        <div class="headerL"></div>
      </div>
      <!-- <van-sticky class="headerSticky" :offset-top="0">
        <img class="headerL" src="@/assets/img/home/menuIcon.png" alt="" @click="showNav = !showNav" srcset="">
        <img class="headerR" :src="schoolInfo.logoUrl || ''" alt="">
        <div class="headerL"></div>
      </van-sticky> -->
    </div>
    <van-popup class="navContent" v-model="showNav" teleport="#loginHome" position="left" :style="{ height: '100%' }">
      <div class="navItem userName">
        <img src="@/assets/img/home/userIcon.png" alt="" srcset=""> {{loginData.realName||''}}
      </div>
      <div class="navBox">
        <div :class="['navItem',{active:navIndex==index}]" v-for="(item, index) in navList" :key="index" @click="handleNavClick(item,index)">{{ item.text }}
        </div>
      </div>
      <!-- get-container="#headerR" -->
      <!-- <template #reference>
      </template> -->
    </van-popup>
    <div class="container">
      <router-view class="routerView"></router-view>
      <div class="footer">
        <div>© 2021-2022 广州市传知汇科技有限公司版权所有</div>
      </div>
    </div>
  </div>
</template>
<script>
import appMethod from '@/components/utils/jssdk'
export default {
  name: 'loginHome',
  props: [],
  watch: {
    $route: {
      immediate: true,
      handler(newVal, oldVal) {
        // console.log(newVal)
        this.deviceType = appMethod.isZHXTapp();
        this.$store.commit('changeDeviceType', this.deviceType);
        this.changeNav(newVal)
        // if(newVal.meta.isShowNav) {
        //   this.isShowNavBar = true;
        //   document.getElementsByTagName('body')[0].style.setProperty('--cont-height','44px')
        // } else {
        //   this.isShowNavBar = false;
        //   document.getElementsByTagName('body')[0].style.setProperty('--cont-height','0')
        // }
      }
    }
  },
  data() {
    return {
      navData: {
        title: ''
      },
      deviceType: '', // 1.ios 2.Android 3.PC 4.微信-ios 5.微信-安卓
      netStatus: '',
      isShowNavBar: true,
      schoolInfo: {},
      loginData: {},
      showNav: false,
      navIndex:0,
      navList: [
        { text: '学生基础管理', goto: 'stuBasicInfo' },
        // { text: '直播管理', goto: 'liveManage' },
        // {
        //   text: '招生简章', moduleName: '招生简章', goto: 'homePageList', query: {
        //     type: 2
        //   }
        // },
        // {
        //   text: '新闻动态', moduleName: '新闻动态', goto: 'homePageList', query: {
        //     type: 0
        //   }
        // },
        // {
        //   text: '通知公告', moduleName: '通知公告', goto: 'homePageList', query: {
        //     type: 1
        //   }
        // },
      ],
    }
  },
  beforeCreate() {
  },
  created() {
    console.log(!sessionStorage.getItem('loginData'),"sessionStorage.getItem('loginData')");
    if (!sessionStorage.getItem('loginData')) {
      console.log('login');
      this.$way.goto('login')
    } else {
      if (sessionStorage.getItem('schoolInfo')) {
        this.schoolInfo = JSON.parse(sessionStorage.getItem('schoolInfo'))
      }
      if (sessionStorage.getItem('loginData')) {
        this.loginData = JSON.parse(sessionStorage.getItem('loginData')).user_info
      }
      this.$way.goto(this.navList[0].goto, this.navList[0].query)
      document.title = this.navList[0].text
    }
  },
  mounted () {
    this.getUserInfo()
    this.isNetWork()
    window.addEventListener('online', this.updateOnlineStatus)
    window.addEventListener('offline', this.updateOnlineStatus)
  },
  beforeDestroy () {
    window.removeEventListener('online', this.updateOnlineStatus)
    window.removeEventListener('offline', this.updateOnlineStatus)
    // 页面关闭销毁
    sessionStorage.removeItem("token")
    sessionStorage.removeItem("refresh_token")
    sessionStorage.removeItem("loginData")
    sessionStorage.removeItem("key")
    if (sessionStorage.getItem('schoolInfo')) {
      let info = JSON.parse(sessionStorage.getItem('schoolInfo'))
      document.title = info.name
    }
  },
  methods: {
    // 导航点击
    handleNavClick(item) {
      this.$way.goto(item.goto, item.query)
      document.title = item.text
      this.showNav = !this.showNav
    },
    // 初始化进行网络判断
    isNetWork () {
      this.netStatus = navigator.onLine ? 'online' : 'offline'
    },
    // 监听网络状态
    updateOnlineStatus (e) {
      // console.log(e);
      const { type } = e
      this.netStatus = type
      if (type === 'online') { // 网络由异常到正常时触发
        this.Success('网络正常')
      } else if (type === 'offline') { // 网络由正常到异常时触发
        this.Error('网络异常')
      }
    },
    changeNav(data) {
      if(!data.meta.hasOwnProperty('title')) return;
      let val = data.meta.title
      if(this.deviceType == 1) {
        window.webkit.messageHandlers.postTitle.postMessage(val);
      } else if(this.deviceType == 2) {
        window.Android.setNewTitle(val)
      }
      this.navData.title = val
    },
    getUserInfo () {
      console.log(this.deviceType,'deviceType');
      // if(this.deviceType == 1) {
      //   // sessionStorage.setItem('token', '596a31e3-1415-46e7-a4e6-85c2279dccc3')
      //   // sessionStorage.setItem('tenant_id', 129)
      //   window.webkit.messageHandlers.getData.postMessage('tokenid')
      //   window.getUserInfo = appMethod.getUserInfo
      // } else if(this.deviceType == 2) {
      //   let userData = JSON.parse(window.Android.getUserInfo())
      //   sessionStorage.setItem('token', userData.token || '')
      //   sessionStorage.setItem('tenant_id', userData.tenantid || '')
      //   this.$store.commit('changeisGetToken', true)
      // } else
      if(this.deviceType == 4 || this.deviceType == 5) {
        // sessionStorage.setItem('token', '')
        // sessionStorage.setItem('tenant_id', '')
      } else {
        // 小官网本地需要注释掉
        // sessionStorage.setItem('token', 'e04d0250-3c48-4e86-9023-aba40cd596cd')
        // sessionStorage.setItem('tenant_id', 172)
        // this.$store.commit('changeisGetToken', true)
      }
    }
  },
}
</script>

<style lang='scss' scoped>
.loginHome {
  width: 100%;
  height: 100vh;
  position: relative;
  -webkit-overflow-scrolling: touch;
    overflow: auto!important;
  .header {
    background: #FFFFFF;
    height: 44px !important;
    overflow: hidden;
    .headerSticky {
      justify-content: space-between;
      padding: 11px 16px;
      display: flex;
      align-items: center;
      .headerL {
        width: 20px;
        height: 16px;
        img {}
      }

      .headerR {
        height: 22px  ;
        img {}
      }
      ::v-deep .van-sticky {
      }
    }
  }

  .navContent {
    width: 45vw;
    background: #F9F9F9;

    .navItem,
    .userName {
      padding: 16px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #363840;
      line-height: 20px;
      background: #FFFFFF;

      &.userName {
        border-bottom: 1px solid #F9F9F9;
        display: flex;
        align-items: center;

        img {
          width: 28px;
          height: 28px;
          margin-right: 8px;
        }
      }
    }

    .navBox {
      .navItem {
        &.active {
          background:rgba(26 ,126 ,226 , 0.1);
        }
      }
    }

    undefined {}
  }

  .container {
    clear: both;
    // height: calc(100vh - 44px);
    overflow: hidden;
    overflow-y: scroll;
    router-view {}
    .routerView {
      min-height: calc(100vh - 80px);
    }
    .footer {
      height: 40px;
      background: #1A7EE2;
      display: flex;
      justify-content: center;
      align-items: center;
      div {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 12px;
      }
    }
  }
}
</style>
