import request from '../axios'
import { formPost } from '../axios'
import qs from 'qs'

const caiRequest = {
  // 登录
  loginYk(data,id,randomStr) {
    // let val = new URLSearchParams();
    // val.append('username', data.username);
    // val.append('password', data.password);
    let dataObj = qs.stringify({ 'username': data.username, 'password': data.password })
    let headers = {
      isToken: false,
      Authorization: "Basic cGlnOnBpZw==",
      "Content-Type": "application/x-www-form-urlencoded",
    };
    if (id) headers["TENANT-ID"] = id;
    return request({
      url: '/auth/oauth/token',
      headers,
      method: 'post',
      params: { randomStr: randomStr || 'blockPuzzle', grant_type: 'password', code: data.code },
      data: dataObj
    })
  },

  // 退出登录
  logout() {
    return request({
      url: '/auth/token/logout',
      method: 'delete'
    })
  },

  //获取验证图片  以及token
  reqGet(params) {
    return request({
      url: '/code',
      method: 'get',
      params
    })
  },

  //滑动或者点选验证
  reqCheck(data) {
    return request({
      url: '/code/check',
      method: 'post',
      params: data
    })
  },

  //刷新token
  refreshToken(data) {
    let dataObj = qs.stringify({ "refresh_token": data });
    return request({
      url: '/auth/oauth/token',
      headers: {
        isToken: false,
        'TENANT-ID': '0',
        'Authorization': 'Basic cGlnOnBpZw==',
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      method: 'post',
      params: { grant_type: 'refresh_token' },
      data: dataObj
    })
  },

  // 第一次登录修改密码接口
  firstLogin(data, token, id) {
    return request({
      url: '/admin/center/pt/first/updatePassword',
      headers: {
        'Authorization': 'Bearer ' + token,
        'TENANT-ID': id,
      },
      method: 'put',
      data
    })
  },

  // 获取本用户的权限菜单
  getMenus(data) {
    return request({
      url: '/admin/menu/getMenu',
      method: 'get',
      data
    })
  },

  // 图片上传
  uploadPic(data, callback) {
    formPost('/manage/file/upload', data).then(res =>{
      return callback(res)
    })
  },

  // 多文件上传
  uploadPics(data, callback) {
    let form = new FormData();
    data.forEach((item, index) => {
      form.append('files', item);
    })
    return request({
      url: '/manage/file/uploadFiles',
      method: 'post',
      headers: { "content-type": "multipart/form-data" },
      data: form
    })
  },

  // 获取函授站下拉框
  getTheDropDownBoxs(params) {
    return request({
      url: '/manage/sysStations/getTheDropDownBox',
      method: 'get',
      params
    })
  },

  // 获取学校logo
  getSchoolInfos() {
    return request({
      url: '/manage/systenant/school/info',
      method: 'get'
    })
  },

  // 学生端通知公告
  studentNotices() {
    return request({
      url: '/manage/student/home/notices',
      method: 'get'
    })
  },

  // 通过id查询
  getNewsById(params) {
    return request({
      url: `/manage/news/getById/${params.newId}`,
      method: 'get'
    })
  },

  // 我的成绩
  // 获取成绩单
  getGrade() {
    return request({
      url: '/manage/student/grade/get',
      method: 'get'
    })
  },

  // 获取课程成绩
  getCourseGrade(params) {
    return request({
      url: '/manage/student/grade/course/get',
      method: 'get',
      params
    })
  },

  // 用户协议
  // 通过栏目id查看文章(用户协议&隐私条款可用)
  getArticleContById(params, callback) {
    return request({
      url: `/admin/article/getArticleContentByCategoryId/${params.categoryId}`,
      method: 'get'
    })
  },

  // 版本管理
  getNewVersion(params) {
    return request({
      url: '/manage/student/version/getTheLatestVersion',
      method: 'get',
      params
    })
  },

  // 帮助中心
  // 获取栏目
  getColumn(params) {
    return request({
      url: `/admin/article/getColumn/${params.id}`,
      method: 'get'
    })
  },

  // 通过栏目id获取文章列表
  getArticleBycolId(params) {
    return request({
      url: `/admin/article/getSysArticleListByCategoryId/${params.id}`,
      method: 'get'
    })
  },

  // 通过文章id查看文章
  watchArticle(params) {
    return request({
      url: `/admin/article/getArticleContentById/${params.id}`,
      method: 'get'
    })
  },

  // 获取教辅材料id
  getBookRecordId(data) {
    return request({
      url: '/manage/student/courseStudy/getEbookRecordId',
      method: 'post',
      data
    })
  },

  // 轮询教辅材料进度
  postBook(data) {
    return request({
      url: '/manage/student/courseStudy/ebookStudy',
      method: 'post',
      data
    })
  },

  // 个人资料
  // 查询教育经历
  queryEduList() {
    return request({
      url: '/manage/student/person/experience/edu/list',
      method: 'get'
    })
  },

  // 新增教育经历
  addEduList(data) {
    return request({
      url: '/manage/student/person/experience/edu/add',
      method: 'post',
      data
    })
  },

  // 修改经历
  updateEduList(data) {
    return request({
      url: '/manage/student/person/experience/update',
      method: 'put',
      data
    })
  },

  // 删除经历
  deleteEduList(params) {
    return request({
      url: '/manage/student/person/experience/delete',
      method: 'delete',
      params
    })
  },

  // 查询工作经历
  queryWorkList() {
    return request({
      url: '/manage/student/person/experience/work/list',
      method: 'get'
    })
  },

  // 新增工作经历
  addWorkList(data) {
    return request({
      url: '/manage/student/person/experience/work/add',
      method: 'post',
      data
    })
  },

  // 获取家庭成员列表
  queryFamilyList() {
    return request({
      url: '/manage/student/person/family/list',
      method: 'post'
    })
  },

  // 新增家庭成员
  addFamilyList(data) {
    return request({
      url: '/manage/student/person/family/add',
      method: 'post',
      data
    })
  },

  // 删除家庭成员
  deleteFamilyList(params) {
    return request({
      url: '/manage/student/person/family/delete',
      method: 'delete',
      params
    })
  },

  // 修改家庭成员
  updateFamilyList(data) {
    return request({
      url: '/manage/student/person/family/update',
      method: 'put',
      data
    })
  },

  // 签到
  offlineteachSignIn(params) {
    return request({
      url: '/manage/student/offlineteaching/checkIn',
      method: 'put',
      params
    })
  },
  
  // 线下授课详情
  getOfflineteachingDetails(params) {
    return request({
      url: `/manage/tchofflineteaching/details/${params}`,
      method: 'get',
      // params
    })
  },
  
  // 获取签到状态
  offlineCourseStatus(params) {
    return request({
      url: '/manage/student/offlineteaching/checkInStatus',
      method: 'put',
      params
    })
  },

  // 咨询联系
  getContactPhone(params) {
    return request({
      url: `manage/student/person/phones`,
      method: "get",
    });
  },

  // 前置学历清查
  // 前置学历材料-获取
  getFrontDegree() {
    return request({
      url: 'manage/student/admit/degreeFont/get',
      method: "get"
    });
  },
  // 前置学历提交材料
  submitFrontDegree(data) {
    return request({
      url: 'manage/student/admit/degreeFont/comment',
      method: "put",
      data
    });
  },

  // 学位申请表查看
  getApplication() {
    return request({
      url: 'manage/student/person/degree/application',
      method: "get"
    });
  },
  // 学位申请表查看-提交
  applicationSubmit(data) {
    return request({
      url: 'manage/student/person/degree/application/submit',
      method: "post",
      data
    });
  },
}


export default caiRequest;
