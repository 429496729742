import appMethod from './jssdk'

// 判断是否获取了token
const isHasToken = () => {
  return !!sessionStorage.getItem('token')
}

// 获取token和租户id
const getZhxtToken = () => {
  const deviceType = appMethod.isZHXTapp()
  console.log(deviceType, ' 设备类型')

  if (deviceType == 1) {
    window.webkit.messageHandlers.getData.postMessage('tokenid')
    window.getUserInfo = appMethod.getUserInfo
  } else if (deviceType == 2) {
    const userData = JSON.parse(window.Android.getUserInfo())
    sessionStorage.setItem('token', userData.token || '')
    sessionStorage.setItem('tenant_id', userData.tenantid || '')
    this.$store.commit('changeisGetToken', true)
  }
}

export default {
  isHasToken,
  getZhxtToken
}
