import store from '../../store'

const appMethod = {
  // 判断设备
  isZHXTapp () {
    // if (window.webkit != undefined) {
    //   return 1
    // } else if (window.Android) {
    //   return 2
    // } else {
    //   return 3 // 本地终端
    // }
    // 判断端口
    var browser = {
      versions: (function () {
        var u = navigator.userAgent
        var app = navigator.appVersion
        return { // 移动终端浏览器版本信息
          WxApp: u.indexOf('MicroMessenger') > -1, // 微信打开
          QQApp: u.indexOf('QQ') > -1, // QQ打开
          DingApp: u.indexOf('DingTalk') > -1, // 钉钉打开
          trident: u.indexOf('Trident') > -1, // IE内核
          presto: u.indexOf('Presto') > -1, // opera内核
          webKit: u.indexOf('AppleWebKit') > -1, // 苹果、谷歌内核
          gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') == -1, // 火狐内核
          mobile: !!u.match(/AppleWebKit.*Mobile.*/), // 是否为移动终端
          ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), // ios终端
          android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1, // android终端或者uc浏览器
          iPhone: u.indexOf('iPhone') > -1, // 是否为iPhone或者QQHD浏览器
          iPad: u.indexOf('iPad') > -1, // 是否iPad
          webApp: u.indexOf('Safari') == -1 // 是否web应该程序，没有头部与底部
        }
      }()),
      language: (navigator.browserLanguage || navigator.language).toLowerCase()
    }
    console.log(JSON.stringify(browser.versions))
    console.log(JSON.stringify(browser.versions?.webkit), 'ios')
    console.log(JSON.stringify(browser.Android), 'android')

    if (browser.versions.WxApp || browser.versions.mobile || browser.versions.ios || browser.versions.android || browser.versions.iPhone || browser.versions.iPad) {
      // 是否微信打开
      if (browser.versions.WxApp) {
        // alert('wx')
        if (browser.versions.ios) {
          // 微信-ios
          return 4
        } else if (browser.versions.android) {
          // 微信-安卓
          return 5
        }
      } else if (browser.versions.QQApp || browser.versions.DingApp) {
        // QQ 或 钉钉环境
        return browser.versions.QQApp ? 6 : 7
      } else if (browser.versions.ios) {
        // alert('ios')
        return 1
      } else if (browser.versions.android) {
        // 安卓
        //  alert('an')
        return 2
      }
    } else {
      // alert(4)
      // pc端 浏览器打开
      return 3
    }
  },
  // 当设备为ios，通过该方法获取token和tenantid
  getUserInfo (result) {
    const res = JSON.parse(result)
    sessionStorage.setItem('token', res.token || '')
    sessionStorage.setItem('tenant_id', res.tenantid || '')
    store.commit('changeisGetToken', true)
  },
  getPaizhaoFile (result) {
    const res = JSON.parse(result)
    console.log(res)

    return res
  }
}

export default appMethod
