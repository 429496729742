import * as CryptoJS from 'crypto-js'
import IdentityCodeValid from './validateIdent'
import router from '../router'
/**
 *根据数据条数与每页多少条数据计算页数
 * totalnum 数据条数
 * limit 每页多少条
 */

function pageCount (totalnum, limit) {
  // return new Promise((resolve, reject) => {
  //   resolve(totalnum > 0 ? ((totalnum < limit) ? 1 : ((totalnum % limit) ? (parseInt(totalnum / limit) + 1) : (totalnum / limit))) : 0);
  // })
  return totalnum > 0 ? ((totalnum < limit) ? 1 : ((totalnum % limit) ? (parseInt(totalnum / limit) + 1) : (totalnum / limit))) : 0
}

/**
 * 获取当前系统时间
 */
Date.prototype.Format = function (fmt) {
  var o = {
    'M+': this.getMonth() + 1, // 月份
    'd+': this.getDate(), // 日
    'H+': this.getHours(), // 小时
    'm+': this.getMinutes(), // 分
    's+': this.getSeconds(), // 秒
    'q+': Math.floor((this.getMonth() + 3) / 3), // 季度
    S: this.getMilliseconds() // 毫秒
  }
  if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + '').substr(4 - RegExp.$1.length))
  for (var k in o) { if (new RegExp('(' + k + ')').test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length))) }
  return fmt
}

export const dateTime = new Date().Format('yyyy-MM-dd HH:mm:ss')
export const date = new Date().Format('yyyy-MM-dd')

/**
 * 时间戳转年月日
 * 例如：1563206400	return	2019-07-16
 */
const timestampReturnDate = (timestamp, type = 'date', gap = '-') => {
  if (!timestamp) return
  const timestamps = Number(timestamp)
  const date = new Date(timestamps)
  const y = date.getFullYear()
  let m = date.getMonth() + 1
  m = m < 10 ? ('0' + m) : m
  let d = date.getDate()
  d = d < 10 ? ('0' + d) : d
  let h = date.getHours()
  h = h < 10 ? '0' + h : h
  let minute = date.getMinutes()
  let second = date.getSeconds()
  minute = minute < 10 ? '0' + minute : minute
  second = second < 10 ? '0' + second : second
  let timeVal = y + '-' + m + '-' + d
  if (type == 'datetime') {
    timeVal = y + '-' + m + '-' + d + ' ' + '\xa0' + h + ':' + minute + ':' + second
  }
  if (type == 'timeYMDHM') {
    timeVal = y + '-' + m + '-' + d + ' ' + '\xa0' + h + ':' + minute
  }
  if (type == 'timeHMS') {
    timeVal = h + ':' + minute + ':' + second
  }
  if (type == 'timeHM') {
    timeVal = h + ':' + minute
  }
  if (type == 'timeMS') {
    timeVal = minute + ':' + second
  }
  if (type == 'dateYM') {
    timeVal = y + '.' + m
  }
  if (type == 'dateD') {
    timeVal = d
  }
  if (type == 'dateY') {
    timeVal = y
  }
  return timeVal
}

/**
 * 年月日转时间戳
 * 例如：Mon Nov 18 2019 10:56:36 GMT+0800 (中国标准时间)/2019-07-16/2019:07:16	return	1563206400
 * @param {String} time [时间]
 */
const dateReturnTimestamp = (time) => {
  var date = new Date(time)
  return date.valueOf()
}

/*
 * 文件下载--通过url下载
 */

const downloadFileUrl = (obj) => {
  console.log(obj)
  var element = document.createElement('a')
  element.setAttribute('href', obj.url)
  element.setAttribute('download', obj.fileName)
  element.style.display = 'none'
  document.body.appendChild(element)
  element.click()
  document.body.removeChild(element)
}

/*
 * 文件下载--通过文件流下载
 */

const downloadFile = (val, name) => {
  const blob = new Blob([val])
  const fileName = name + '.xlsx'
  const elink = document.createElement('a')
  elink.download = fileName
  elink.style.display = 'none'
  elink.href = URL.createObjectURL(blob)
  document.body.appendChild(elink)
  elink.click()
  URL.revokeObjectURL(elink.href) // 释放URL 对象
  document.body.removeChild(elink)
}

/*
 * 作用：文件上传
 */
const mediaFilesUpload = (file) => {
  return new Promise(resolve => {
    const reader = new FileReader()
    // 转base64
    reader.onload = function (e) {
      resolve(e.target.result)
    }
    reader.readAsDataURL(file)
  })
}

/*
 * 作用：非媒体文件上传
 */
const officeFilesUpload = (data) => {
  return new Promise(resolve => {
    const file = data.file
    const forms = new FormData()
    forms.append('file', file)
    resolve(forms)
  })
}

// 图片上传oss
const uploadOss = (OSS, file, info) => {
  return new Promise((resolve, reject) => {
    const client = new OSS({
      accessKeyId: process.env.VUE_APP_OSS_ACCESSKEYID,
      accessKeySecret: process.env.VUE_APP_OSS_ACCESSKEYSECRET,
      region: process.env.VUE_APP_OSS_REGION,
      bucket: process.env.VUE_APP_OSS_BUKET
    })
    const ym_date = new Date().Format('yyyyMM')
    const time = dateReturnTimestamp(new Date())
    const fileNameArr = file.name.split('.')
    const suffix = `${info}/${ym_date}/${fileNameArr[0]}_${time}.${fileNameArr[1]}`
    // console.log(suffix,985211);
    client.multipartUpload(suffix, file).then(res => {
      // console.log(res,99999)
      resolve(decodeURIComponent(res.res.requestUrls[0]))
    }).catch(err => {
      // console.log(err.response,985211)
      console.log(err)
    })
  })
}

/**
 * @desc 防抖函数
 * @param {需要防抖的函数} func
 * @param {延迟时间} wait
 * @param {是否立即执行} immediate
 */
const debounce = (fn, wait, immediate) => {
  let timer
  return function () {
    if (timer) clearTimeout(timer)
    if (immediate) {
      // 如果已经执行过，不再执行
      var callNow = !timer
      timer = setTimeout(() => {
        timer = null
      }, wait)
      if (callNow) {
        fn.apply(this, arguments)
      }
    } else {
      timer = setTimeout(() => {
        fn.apply(this, arguments)
      }, wait)
    }
  }
}

// 节流
export function throttle (fn, delay) {
  var lastTime
  var timer
  var delay = delay || 200
  return function () {
    var args = arguments
    // 记录当前函数触发的时间
    var nowTime = Date.now()
    if (lastTime && nowTime - lastTime < delay) {
      clearTimeout(timer)
      timer = setTimeout(function () {
        // 记录上一次函数触发的时间
        lastTime = nowTime
        // 修正this指向问题
        fn.apply(this, args)
      }, delay)
    } else {
      lastTime = nowTime
      fn.apply(this, args)
    }
  }
}

// 设置手机号的验证规则
const regPhone = (value) => {
  const reg = /^1[3|4|5|6|7|8|9]\d{9}$/
  if (reg.test(value)) {

  } else {
    return new Error('请输入正确的电话')
  }
}

// 设置手机号的验证规则
const regPhoneNew = (value) => {
  const reg = /^1[3|4|5|6|7|8|9]\d{9}$/
  if (reg.test(value)) {
    return true
  } else {
    return false // 请输入正确的手机号码
  }
}

// 设置邮箱的验证规则
const regEmail = (value) => {
  const reg = /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/
  if (!reg.test(value)) {
    return new Error('请输入正确的邮箱')
  } else {

  }
}

// 设置邮箱的验证规则
const regEmailNew = (value) => {
  const reg = /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/
  if (!reg.test(value)) {
    return false // 请输入正确的邮箱
  } else {
    return true
  }
}

// 设置数据为数字的验证规则
const regNumber = (value) => {
  var regPos = /^\d+(\.\d+)?$/ // 非负浮点数
  var regNeg = /^(-(([0-9]+\.[0-9]*[1-9][0-9]*)|([0-9]*[1-9][0-9]*\.[0-9]+)|([0-9]*[1-9][0-9]*)))$/ // 负浮点数
  if (regPos.test(value) || regNeg.test(value)) {
    return true
  } else {
    return false
  }
}

// 校验特殊符号
function checkSpecificKey (str) {
  var specialKey = "[`~!#$^&*()=|{}':;',\\[\\].<>/?~！#￥……&*（）——|{}【】‘；：”“'。，、？]‘'"
  for (var i = 0; i < str.length; i++) {
    if (specialKey.indexOf(str.substr(i, 1)) != -1) {
      return false
    }
  }
  return true
}

// 不为特殊字符
const regSpecil = (value) => {
  if (!checkSpecificKey(value)) {
    return false
  } else {
    return true
  }
}

// 中文校验
const regChinese = (value) => {
  if (/[\u4E00-\u9FA5]/g.test(value)) {
    return false // 不能输入中文
  } else {
    // 验证通过
    return true
  }
}

// 数据有字母和数字组成
const regNumletter = (value) => {
  const reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,16}$/
  if (!reg.test(value)) {
    return new Error('密码必须是由8-16位字母+数字组合')
  } else {

  }
}

// 去除输入框内的空格
const clearSpace = (value) => {
  return value.replace(/\s*/g, '')
}

// 判断该页面导出、导入、查询等按钮是否有权限，有权限显示，无权限隐藏
const isShowModle = (val) => {
  const arr = JSON.parse(sessionStorage.getItem('hasPerData'))
  let isShow = false
  arr.forEach((item, index) => {
    if (item == val) {
      isShow = true
    }
  })
  return isShow
}
// 路由跳转
function goto (name, query, url) {
  router.push({
    name: name,
    url: url,
    query: {
      ...query
    }
  })
}

/*
 * 身份证校验
 */
const regIDCard = (value) => {
  if (IdentityCodeValid(value)) {
    // 验证通过
    return true
  } else {
    // 格式不正确
    return false
  }
}

/**
 *加密处理
 */
const encryption = (params) => {
  let {
    data,
    type,
    param,
    key
  } = params
  const result = JSON.parse(JSON.stringify(data))
  if (type === 'Base64') {
    param.forEach(ele => {
      result[ele] = btoa(result[ele])
    })
  } else {
    param.forEach(ele => {
      var data = result[ele]
      key = CryptoJS.enc.Latin1.parse(key)
      var iv = key
      // 加密
      var encrypted = CryptoJS.AES.encrypt(
        data,
        key, {
          iv: iv,
          mode: CryptoJS.mode.CFB,
          padding: CryptoJS.pad.NoPadding
        })
      result[ele] = encrypted.toString()
    })
  }
  return result
}

// 判断是否为img文件
const isImageType = (url) => {
  const index = url.lastIndexOf('.')
  const ext = url.substr(index + 1)
  // toLowerCase() 将字符串转换为小写，返回一个新的字符串
  return ['png', 'jpg', 'jpeg', 'bmp', 'gif', 'webp', 'psd', 'svg', 'tiff'].indexOf(ext.toLowerCase()) !== -1
}

// 上传文件判断文件名是否带有某些浏览器不能解析的字符
const checkUrl = (value) => {
  const pattern = new RegExp('[+%]')
  if (pattern.test(value)) {
    // 包含
    return true
  } else {
    // 不包含
    return false
  }
}

export default {
  pageCount,
  timestampReturnDate,
  dateReturnTimestamp,
  downloadFileUrl,
  downloadFile,
  mediaFilesUpload,
  officeFilesUpload,
  uploadOss,
  debounce,
  throttle,
  regPhone,
  regPhoneNew,
  regEmail,
  regEmailNew,
  regNumber,
  regSpecil,
  regChinese,
  regNumletter,
  clearSpace,
  encryption,
  isShowModle,
  regIDCard,
  goto,
  isImageType,
  checkUrl
}
