/*
 * @Author: chenjg
 * @Date: 2019-11-01 10:16:47
 * @LastEditTime: 2021-12-11 10:15:07
 * @LastEditors: Please set LastEditors
 * @Description: vant.js
 * @输出一段不带属性的自定义信息
 */
import Vue from 'vue'
import {
  Button,
  Cell,
  CellGroup,
  Toast,
  Field,
  Lazyload,
  Radio,
  RadioGroup,
  Checkbox,
  CheckboxGroup,
  Swipe,
  SwipeItem,
  Image,
  List,
  CountDown,
  Icon,
  Dialog,
  ActionSheet,
  Switch,
  Tab,
  Tabs,
  Slider,
  Popup,
  step,
  ImagePreview,
  Steps,
  Loading
} from 'vant'

Vue
  .use(Loading)
  .use(Button)
  .use(Cell)
  .use(CellGroup)
  .use(Toast)
  .use(Field)
  .use(Lazyload)
  .use(Radio)
  .use(RadioGroup)
  .use(Checkbox)
  .use(CheckboxGroup)
  .use(Swipe)
  .use(SwipeItem)
  .use(Image)
  .use(List)
  .use(CountDown)
  .use(Icon)
  .use(Dialog)
  .use(ActionSheet)
  .use(Switch)
  .use(Tab)
  .use(Tabs)
  .use(Slider)
  .use(Popup)
  .use(step)
  .use(Steps)
  .use(ImagePreview)

// 图片预览方法
function longPressImg (event) {
  let target = event.target
  if (target.nodeName === 'IMG') {
    event.stopPropagation()
    event.preventDefault()
    ImagePreview({
      images: [target.src],
      showIndex: false,
      closeable: true
    })
  }
}

Vue.directive('longpress', {
  bind: function (el, binding, vNode) {
    // Make sure expression provided is a function
    if (typeof binding.value !== 'function' && vNode.context !== undefined) {
      // pass warning to console
      let warn = `[longpress:] provided expression '${binding.expression}' is not a function, but has to be`

      console.warn(warn)
    }

    // Define variable
    let pressTimer = null

    // Define funtion handlers
    // Create timeout ( run function after 1s )
    let start = (e) => {
      if (e instanceof MouseEvent && e.type === 'click' && e.button !== 0) {
        return
      }

      if (pressTimer === null) {
        pressTimer = setTimeout(() => {
          // Run function
          handler(e)
        }, 1000)
      }
    }

    // Cancel Timeout
    let cancel = () => {
      // Check if timer has a value or not
      if (pressTimer !== null) {
        clearTimeout(pressTimer)
        pressTimer = null
      }
    }
    // Run Function
    const handler = (e) => {
      longPressImg(e)
    }

    // Add Event listeners
    el.addEventListener('mousedown', start)
    el.addEventListener('touchstart', start)
    // Cancel timeouts if this events happen
    el.addEventListener('click', cancel)
    el.addEventListener('mouseup', cancel)
    el.addEventListener('mouseout', cancel)
    el.addEventListener('touchend', cancel)
    el.addEventListener('touchcancel', cancel)
  }
})
